






















import info from "@/components/_universal/list-info.vue";
import tablelist from "@/components/_universal/list-table.vue";
// import advancedTable from "@/components/_universal/advanced-table/advanced-table.vue";

//Standard Components
import EVENTS from "@/constants/events";

//Controller
import ClientsController from "@/controllers/clients-controller";

import listMixin from "@/mixin/list-mixin";

//Initialize Controller and pass data to be loaded/displayed
const clientsController = new ClientsController();

export default {
  mixins: [listMixin],
  data() {
    return {
      listDataObj: {} as any,
      EVENT_ACTION: {},
      project: {},
      headers: [
        {
          id: 1,
          title: "Rating",
          columnName: "total",
          isSortable: true,
          isVisible: true,
          rightAlign: false,
          colSize: "col-1",
        },
        {
          id: 3,
          title: "Client",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
          colSize: "col-2",
        },
        {
          id: 3,
          title: "Industry",
          columnName: "total",
          isSortable: true,
          isVisible: true,
          rightAlign: false,
          colSize: "col-2",
        },
        {
          id: 4,
          title: "Location",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
          colSize: "col-3",
        },
        {
          id: 4,
          title: "Phone",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
          colSize: "col",
        },
        {
          id: 4,
          title: "Created By",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: false,
          colSize: "col",
        },
        {
          id: 4,
          title: "Created On",
          columnName: "total",
          isSortable: false,
          isVisible: true,
          rightAlign: true,
          colSize: "col",
        },
      ],
    };
  },
  methods: {
    async onFilterChange() {
      //Get current filter from store
      if (this.$store.getters.getList.filter) this.filter = this.$store.getters.getList.filter;

      //Call API pass new filter object paramater
      let data = await clientsController.getListResults(this.filter);

      //Update Store results
      await this.updateResults(data.resources);
    },
    async loadData() {
      this.filter.exclude = "ClientType:LostAccount";

      //Set Header Properties to be passed in as prop values
      this.listDataObj = await clientsController.list(this.filter, "Commercial");

      //Save the current list in the store so we can reference it if it updates.
      if (this.listDataObj.table.data.success) {
        this.updateResults(this.listDataObj.table.data.resources);
      }
    },

    //Add results to the store
    async updateResults(results) {
      this.$store.dispatch("setList", results);
    },

    //Check for changes, if the user changed the filter, to refresh the api
    subscribeOnFilterChange() {
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === "setFilter") {
          this.onFilterChange();
        }
      });
    },
  },
  components: {
    info,
    tablelist,
    // advancedTable
  },
  async mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window["analytics"].page(this.EVENT_ACTION.CLIENTS.View);
    // }

    this.subscribeOnFilterChange();

    //Reload Data every 5 seconds
    this.timer = setInterval(async () => {
      await this.onFilterChange();
    }, 5000);
  },
  async created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    await this.loadData();
  },
  beforeDestroy() {
    //Remove data from vuex store
    this.updateResults(null);
    this.unsubscribe();
    clearTimeout(this.timer);
    this.timer = null;
  },
};
